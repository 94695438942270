import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from '../../assets/heart.png';
import Calories from "../../assets/calories.png";
import  {motion} from 'framer-motion'
import NumberCounter from 'number-counter'



const Hero = () => {
  const transition ={type: 'spring',duration : 3}
  const mobile= window.innerWidth<=768 ? true:false;
  return (
    <div className="hero">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "160px" : "210px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>the best fitness club in the town</span>
        </div>
        <div className="hero-text">
          <div>
            <span className="stroke-text"> Shape </span>
            <span> Your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div>
            <span>
              In here we help you toshape and build your ideal body and live up
              your life to the fullest
            </span>
          </div>
        </div>
        <div className="figures">
          <div>
            <span>
              <NumberCounter end={150} start={110} delay="4" preFix="+" />
            </span>
            <span>expect coaches</span>
          </div>
          <div>
            <span>
              <NumberCounter end={1078} start={1000} delay="4" preFix="+" />
            </span>
            <span>members joined</span>
          </div>
          <div>
            <span>
              <NumberCounter end={60} start={15} delay="4" preFix="+" />
            </span>
            <span>fitness programs</span>
          </div>
        </div>
        <div className="hero-buttons">
          <buttons className="btn">Get Started</buttons>
          <buttons className="btn">Learn More</buttons>
        </div>
      </div>
      <div className="right-h">
        <button className="btn">Join Now</button>
        <motion.div
          className="heart-rate"
          transition={transition}
          initial={{ right: "-1rem" }}
          whileInView={{ right: "4rem" }}
        >
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>112bpm</span>
        </motion.div>
        <img src={hero_image} alt="" className="hero-image" />
        <motion.img
          transition={transition}
          initial={{ right: "11rem" }}
          whileInView={{ right: "20rem" }}
          src={hero_image_back}
          alt=""
          className="hero-image-back"
        />
        <motion.div
          className="calories"
          transition={transition}
          initial={{ right: "37rem" }}
          whileInView={{ right: "28rem" }}
        >
          <img src={Calories} alt="" />
          <div>
            <span>Calories Burned </span>
            <span>280 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default Hero